import React, { Fragment } from "react";
import { PropTypes } from "prop-types";
export const INTERNAL_ERROR_CODE = "4003";
export const PAKINVALID_ERROR_CODE = "4001";
export const PAKDUPLICATE_ERROR_CODE = "4009";
export const PAKRESTRICTED_ERROR_CODE = "4010";
export const PAKMAX_ERROR_CODE = "4007";
export const PAKMIN_ERROR_CODE = "4008";
export const OMNIVERSE = "https://www.nvidia.com/en-us/omniverse/enterprise/free-trial/";

export const PAKINVALID_ERROR_Message =
  "Error code 4001 - Please check the PAK ID that you are entering. Please retry with the correct PAK ID or review";
export const MULTIPLEPECACCOUNTS =
  "Your e-mail ID is linked to multiple accounts in our system. Please contact our enterprise support team PECRegistrationSupport@nvidia.com";
  export const SUCCESSVGPU =
  "Thank you for requesting a vGPU evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.";

export const LINKEDTOANOTHERPECACCOUNTS =
  "Your e-mail ID is already linked to another account. Please register a separate email id to continue or contact PECRegistrationSupport@nvidia.com for further assistance Note: If you are a system integrator/3rd party solution provider, you need to register a separate e-mail ID for each customer.";
export const NPNACCOUNT =
  "Something went wrong, please contact PECRegistrationSupport@nvidia.com with error code 4006";
  export const NGCORGEXISTS =
  "Thank you for registering your entitlement. You received all the access except NGC. Your Org already exists in NGC, and user membership is controlled by your company. Please contact your NGC Org Admin to get access or contact NVIDIA Enterprise Support.";
export const render4001ErrorMessage = (props) => (
  <Fragment>
    Error code {props} - Please check the PAK ID that you are entering. Please
    retry with the correct PAK ID or review{" "}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://www.nvidia.com/en-us/nvidia-commercial-account-faq/'
    >
      Registration FAQ
    </a>{" "}
    for additional instructions.
  </Fragment>
);
export const render4010ErrorMessage = (props) => (
  <Fragment>
    Your registration could not be allowed in compliance with U.S. Government policy.
  </Fragment>
);
export const renderPartnerCancelMessage = (props) => (
  <Fragment>
      Thank you for your interest in NVIDIA Omniverse Enterprise. If you would like to learn more or get your questions answered, please email your partner.
  </Fragment>
);
export const renderVGPUCancelMessage = (props) => (
  <Fragment>
      If you don’t have NVIDIA GPUs, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>
  </Fragment>
);
export const renderSpectrumxCancelMessage = (props) => (
  <Fragment>
      If you don’t have NVIDIA networking, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>
  </Fragment>
);

export const renderNETQCancelMessage = (props) => (
  <Fragment>
     If you don’t have NVIDIA Ethernet Switches, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/networking/netq/free-trial/'
              >
                {" "}
                contact us.{" "}
              </a>
  </Fragment>
);

export const renderNETQSAASCancelMessage = (props) => (
  <Fragment>
     If you don’t have NVIDIA Ethernet Switches, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/networking/netq/free-trial/'
              >
                {" "}
                contact us.{" "}
              </a>
  </Fragment>
);
export const renderUFMCancelMessage = (props) => (
  <Fragment>
    If you don’t have NVIDIA InfiniBand clusters, but would like to purchase, please<a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/networking/buy/'
              >
                {" "}
                contact us.{" "}
              </a>
  </Fragment>
);
export const renderNVAIEMessage = (props) => (
  <Fragment>
     Thank you for requesting a NVIDIA NVAIE evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderEvalNPNMessage = (props) => (
  <Fragment>
    For NPN Partners, please use the Not for Resale (NFR) process:
    <ul>
      <li>
      Log on to the NVIDIA PARTNER NETWORK (NPN)  <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/npnportal/'
              >
                {" "}
                homepage.{" "}
              </a> 
              </li>
     <li>Under MY DASHBOARD, click on the NFR License Request link
     </li>
     </ul>
  </Fragment>
);
export const renderOMNIVERSEMessage = (props) => (
  <Fragment>
     Thank you for requesting a NVIDIA Omniverse Enterprise evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderNetqMessage = (props) => (
  <Fragment>
    Thank you for requesting a NVIDIA NetQ evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);

export const renderNetqSaasMessage = (props) => (
  <Fragment>
    Thank you for requesting a NVIDIA NetQ SaaS evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderUFMMessage = (props) => (
  <Fragment>
    Thank you for requesting a Unified Fabric Manager (UFM®) evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderNVAIEnterpriseMessage = (props) => (
  <Fragment>
    Thank you for requesting a Nvidia AI Enterprise evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderSpectrumXMessage = (props) => (
  <Fragment>
    Thank you for requesting a Spectrum-X evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderPARTNEROMNIVERSEMessage = (props) => (
  <Fragment>
      Thank you for requesting a NVIDIA Omniverse Enterprise evaluation entitlement. We will send you an email with the next steps within 24-48 business hours.
  </Fragment>
);
export const renderNVAIECancelMessage = (props) => (
  <Fragment>
     If you do not have NVIDIA AI Enterprise compatible system, please take a look our qualified server catalog <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/data-center/data-center-gpus/qualified-system-catalog/'
              >
                {" "}
                server catalog.{" "}
              </a>
  </Fragment>
);

export const renderNVAIEnterCancelMessage = (props) => (
  <Fragment>
    If you don’t have NVIDIA Compatible Systems, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>
  </Fragment>
);
export const renderLGSInvalidTokenMessage = (props) => (
  <Fragment>
   Your link for registration has expired.Please contact your Org Admin or  <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/support/enterprise/'
              >
                {" "}
                Enterprise Support.{" "}
              </a>
  </Fragment>
);

export const render4009DuplicateErrorMessage = (props) => (
  <Fragment>
    Error code {props} - This PAK ID has already been claimed by your administrator. Please contact your org admin for more details.
  </Fragment>
);

export const renderErrorMessage = (props) => (
  <Fragment>
    Error code {props} - Something went wrong,please review{" "}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://www.nvidia.com/en-us/nvidia-commercial-account-faq/'
    >
      Registration FAQ
    </a>{" "}
    for additional instructions.
  </Fragment>
);
export const MultiplePecAccounts = (props) => (
  <Fragment>
    Your e-mail ID is linked to multiple accounts in our system,please review{" "}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://www.nvidia.com/en-us/nvidia-commercial-account-faq/'
    >
      Registration FAQ
    </a>{" "}
    for further assistance.
  </Fragment>
);
export const LinkedtoAnotherPecAccounts = (props) => (
  <Fragment>
    Your e-mail ID is already linked to another account. Please register a
    separate email id to continue or review{" "}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://www.nvidia.com/en-us/nvidia-commercial-account-faq/'
    >
      Registration FAQ
    </a>{" "}
    for further assistance. Note: If you are a system integrator/3rd party
    solution provider, you need to register a separate e-mail ID for each
    customer.
  </Fragment>
);
export const NPNAccounts = (props) => (
  <Fragment>
    Error code 4006 -Something went wrong,please review{" "}
    <a
      rel='noopener noreferrer'
      target='_blank'
      href='https://www.nvidia.com/en-us/nvidia-commercial-account-faq/'
    >
      Registration FAQ
    </a>{" "}
    for additional instructions.
  </Fragment>
);
