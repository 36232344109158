import React from "react";
import { PropTypes } from "prop-types";
import * as ProductFamilies from "../../../constants/productFamilies";

const ProductInfo = (props) => {
  const hasTitle = props.title !== undefined && props.title !== "";
  const IsRWA03 = props.isrwa03?true:false;
  const IsRWA04 = props.isrwa04?true:false;
 const isHPI=props.PartnerURLVal&& props.PartnerURLVal.toUpperCase()==="HPI";
 const isInsight=props.PartnerURLVal&& props.PartnerURLVal.toUpperCase()==="INSIGHT";
 const isEscape=props.PartnerURLVal&& props.PartnerURLVal.toUpperCase()==="ESCAPE";
 const isWWT=props.PartnerURLVal&& props.PartnerURLVal.toUpperCase()==="WWT";
 const isDELL=props.PartnerURLVal&& props.PartnerURLVal.toUpperCase()==="DELL";
 const VgpuTitle="Start your 90-day vGPU trial";
 const SpectrumXTitle="Start your 90-day Spectrum-X trial";
 const NVaiEnterTitle="Start your 90-day Nvidia AI Enterprise trial";
 const NetQSaasTitle="Start your 365-day NetQ SaaS trial";
 const NetQTitle="Start your 90-day NetQ trial";
 const UFMTitle="Start your 60-day Unified Fabric Manager (UFM®) Evaluation Registration.";
 const UFMTitle2="Unified Fabric Manager (UFM®) is a powerful platform for managing scale-out computing environments. UFM enables data center operators to efficiently monitor and operate the entire fabric, boost application performance, and maximize fabric resource utilization.";
 const UFMTitle1="Get started today with a 60-day free evaluation of Unified Fabric Manager (UFM®) and see for yourself UFM's power to effectively provision, monitor, and operate your modern InfiniBand fabric.";
 const OmniverseTitle="Start your 30-day Omniverse Enterprise trial";
 let title="";
 const PartnerOmniverseTitle="Start your 90-day Partner Omniverse Enterprise trial";
 const NvaieTitle="Start your 90-day AI Enterprise trial";
 if (isHPI) {
  title ="Hello HP customer, welcome to the Omniverse Enterprise 90 day trial."
} else if(isInsight) {
  title ="Hello Insight customer, welcome to the Omniverse Enterprise 90 day trial."
}
else if(isEscape)
{
  title ="Hello Escape Technology customer, welcome to the Omniverse Enterprise 90 day trial."
}
else if(isWWT)
{
  title ="Hello WWT customer, welcome to the Omniverse Enterprise 90 day trial."
}
else{
title="Start your 90-day Partner Omniverse Enterprise trial";
}

  if (IsRWA03 &&
   props.productfamily.toUpperCase()===ProductFamilies.vgpu
    ) {
     
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
          { <h3>{VgpuTitle}</h3>}
          <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            <li>NVIDIA virtual GPU solutions are designed to run on NVIDIA® GPUs. Please ensure you have the following before downloading the NVIDIA virtual GPU software evaluation edition:
              
               </li>
               <ul>
                    <li>NVIDIA Virtual GPU Certified Servers.<a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/data-center/resources/vgpu-certified-servers/'
              >
                {" "}
                See a complete list of certified systems.{" "}
              </a>{" "}</li>
              <li>
                <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/data-center/graphics-cards-for-virtualization/'
              >
                {" "}
                Supported NVIDIA GPUs:{" "}
              </a>{" "} NVIDIA A10, A16, A30, A40, A100, T4, RTX 8000, RTX 6000, V100S, V100, P100, P40, P6, P4, M60, M10</li>
              <li>
            Virtualization Software from VMware, Citrix, Red Hat, and Nutanix.
            </li>
               </ul>
           
            <li>
              {" "}
              If you don’t have NVIDIA GPUs, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>
            </li>
            
          </ul>
          
        </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.Partneromniverse) {
    return (
      <div className={"infoBlock"}>
        
        <div className={"infoBlockEval"}>

        { <h3>{title}</h3>}
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            <li>
              Please ensure that the person who is registering for the
              evaluation licenses is the person who will be managing those
              licenses.
            </li>
           
            
  
  <li>Please ensure the following requirements are met:
              <ul>
                <li>
                Minimum GPU requirements are RTX-enabled GPUs with 6GB VRAM
                </li>
                <li>
                Recommended GPU requirements are RTX-enabled GPUs with 8GB VRAM
                </li>
              </ul>
              </li>
              
        
              <li>
              For information about supported hardware and software, refer to the system requirements per app or platform 
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://docs.omniverse.nvidia.com/plat_omniverse/plat_omniverse/technical.html'
              >
                {" "}
                here{" "}
              </a>{" "}
                </li>
         
            <li>
              {" "}
              If already registered, click <a href={props.idmlogin}>here</a>
            </li>
            <li>
              If you need assistance with registration or are seeking an
              exception, please review{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/nvidia-omniverse-partner-faq/'
              >
                {" "}
                FAQ{" "}
              </a>{" "}
              .
            </li>
          </ul>
        </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.omniverse) {
    return (
      <div className={"infoBlock"}>
        
        <div className={"infoBlockEval"}>
         
         { <h3>{OmniverseTitle}</h3>} 
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            <li>
              Please ensure that the person who is registering for the
              evaluation licenses is the person who will be managing those
              licenses.
            </li>
            {isDELL && 
                <li>
                Recommended GPU requirements are RTX-enabled GPUs with 8GB VRAM.
                </li>
               
            }
            {!isDELL && 
            <li>Please ensure the following requirements are met:
              <ul>
                <li>
                Minimum GPU requirements are RTX-enabled GPUs with 6GB VRAM
                </li>
                <li>
                Recommended GPU requirements are RTX-enabled GPUs with 8GB VRAM
                </li>
              </ul>
              </li>
  }
              <li>
              For information about supported hardware and software, refer to the system requirements per app or platform 
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://docs.omniverse.nvidia.com/plat_omniverse/plat_omniverse/technical.html'
              >
                {" "}
                here{" "}
              </a>{" "}
                </li>
  


            <li>
              {" "}
              If already registered, click <a href={props.idmlogin}>here</a>
            </li>
            <li>
              If you need assistance with registration or are seeking an
              exception, please review{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/omniverse/enterprise/free-trial/#faqs'
              >
                {" "}
                FAQ{" "}
              </a>{" "}
              .
            </li>
          </ul>
        </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.nvaie) {
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
        { <h3>{NvaieTitle}</h3>}
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            <li>
              Please ensure that the person who is registering for the
              evaluation licenses is the person who will be managing those
              licenses.
            </li>
            <li>
              {" "}
              If already registered, click <a href={props.idmlogin}>here</a>
            </li>
            <li>
              If you need assistance with registration or are seeking an
              exception, please review{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/nvidia-enterprise-account-faq/'
              >
                {" "}
                FAQ{" "}
              </a>{" "}
              
            </li>
          </ul>
          </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.NETQSAAS) {
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
        { <h3>{NetQSaasTitle}</h3>}
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            <li>
            NVIDIA NetQ SaaS is a network monitoring and troubleshooting solution for NVIDIA® Ethernet Switches. Please ensure you have the following before downloading the NVIDIA NetQ software for evaluation:  
            <ul>
                <li>
                NVIDIA {" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href=' https://www.nvidia.com/en-us/networking/ethernet-switching/'
              >
                {" "}
                Spectrum Ethernet Switches
              </a>{" "} {" "} running Cumulus Linux or SONiC.
                </li>
                <li>
                VMware ESXi or KVM server to deploy NetQ software.
               
                </li>
            </ul>
            </li>
           
            <li>
            If you don’t have NVIDIA Spectrum Ethernet Switches, but would like to purchase, please{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/networking/buy/'
              >
                {" "}
                contact us.{" "}
              </a>{" "}
              
            </li>
          </ul>
          </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.NETQ) {
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
        { <h3>{NetQTitle}</h3>}
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            <li>
            NVIDIA NetQ is a network monitoring and troubleshooting solution for NVIDIA® Ethernet Switches. Please ensure you have the following before downloading the NVIDIA NetQ software for evaluation:  
            <ul>
                <li>
                NVIDIA {" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href=' https://www.nvidia.com/en-us/networking/ethernet-switching/'
              >
                {" "}
                Spectrum Ethernet Switches
              </a>{" "} {" "} running Cumulus Linux or SONiC.
                </li>
                <li>
                VMware ESXi or KVM server to deploy NetQ software.
               
                </li>
            </ul>
            </li>
           
            <li>
            If you don’t have NVIDIA Spectrum Ethernet Switches, but would like to purchase, please{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/networking/buy/'
              >
                {" "}
                contact us.{" "}
              </a>{" "}
              
            </li>
          </ul>
          </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.UFM) {
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
        { <p>
          {/* {UFMTitle}
        <br></br>
        {UFMTitle2}
        <br></br> */}
        {UFMTitle1}
        </p>}
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            
            
          
                <li>
                If you would like to purchase UFM, please{" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>{" "}
                </li>
              
          
            
           
            <li>
            If you don’t have NVIDIA InfiniBand clusters, but would like to purchase, please {" "}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/networking/buy/'
              >
                {" "}
                contact us.{" "}
              </a>{" "}
              
            </li>
          </ul>
          </div>
      </div>
    );
  }
  else if(IsRWA03 && props.productfamily.toUpperCase()===ProductFamilies.SpectrumX) {
   
    return (
      <div className={"infoBlock"}>
        
        <div className={"infoBlockEval"}>
         
         { <h3>{SpectrumXTitle}</h3>} 
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
            <li>
            NVIDIA Spectrum-X is designed to run on networks powered by NVIDIA Spectrum-4 Ethernet Switches and BlueField-3 DPUs.
            Please ensure you have the following before downloading the Spectrum-X software evaluation edition:
            <li>NVIDIA Spectrum SN5000 series Ethernet Switches</li>
            <li>NVIDIA BlueField-3 DPUs</li>
            <li>NVIDIA Support for Cumulus Linux NOS or ONIE</li>
            </li>
            <li>
            Without Cumulus or ONIE support, you will be unable to access the NVIDIA Enterprise Support Portal for your Spectrum-X evaluation. 
            If you are unsure whether your organization has registered your email for Cumulus or ONIE support, 
            please contact your support administrator to be added to the NVIDIA Enterprise Support Portal. 
            </li>
                
               
            
         
              <li>
              If you don’t have NVIDIA networking, but would like to purchase,
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>{" "}
                </li>
  


          </ul>
        </div>
      </div>
    );
  }
  else if(IsRWA04 && props.productfamily.toUpperCase()===ProductFamilies.NvaiEnter) {
   
    return (
      <div className={"infoBlock"}>
        
        <div className={"infoBlockEval"}>
         
         { <h3>{NVaiEnterTitle}</h3>} 
        <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            
         
               
            
         
              <li>
              To participate in the evaluation for a free trial, an
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://docs.nvidia.com/ngc/ngc-deploy-on-premises/nvidia-certified-systems/index.html#nvidia-certified-systems-list/'
              >
                {" "}
                NVIDIA-Certified server {" "}
              </a>{" "}compatible with NVIDIA AI Enterprise software suite is required.
                </li>
                <li>
                If you don’t have NVIDIA Compatible Systems, but would like to purchase, please 
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>{" "}
                </li>


          </ul>
        </div>
      </div>
    );
  }
  else if(IsRWA03 ) {
    return (
      <div className={"infoBlock"}>
        <div className={"infoBlockEval"}>
          { <h3>{VgpuTitle}</h3>}
          <h4>
            <b>IMPORTANT TIPS </b>
          </h4>
          <ul>
            <li>Please register with your corporate email address.</li>
            <li>
              Personal email addresses or extensions will not be approved.
            </li>
            <li>NVIDIA virtual GPU solutions are designed to run on NVIDIA® GPUs. Please ensure you have the following before downloading the NVIDIA virtual GPU software evaluation edition:
              
               </li>
               <ul>
                    <li>NVIDIA Virtual GPU Certified Servers.<a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/data-center/resources/vgpu-certified-servers/'
              >
                {" "}
                See a complete list of certified systems.{" "}
              </a>{" "}</li>
              <li>
                <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/data-center/graphics-cards-for-virtualization/'
              >
                {" "}
                Supported NVIDIA GPUs:{" "}
              </a>{" "} NVIDIA A10, A16, A30, A40, A100, T4, RTX 8000, RTX 6000, V100S, V100, P100, P40, P6, P4, M60, M10</li>
              <li>
            Virtualization Software from VMware, Citrix, Red Hat, and Nutanix.
            </li>
               </ul>
           
            <li>
              {" "}
              If you don’t have NVIDIA GPUs, but would like to purchase, please <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.nvidia.com/en-us/contact/sales/'
              >
                {" "}
                contact us.{" "}
              </a>
            </li>
            
          </ul>
          
        </div>
      </div>
    );
  }
  
  
};


ProductInfo.propTypes = {
  PartnerURLVal: PropTypes.string,
  title: PropTypes.string,
  isrwa03: PropTypes.number.isRequired,
  isrwa04: PropTypes.number.isRequired,
   productfamily: PropTypes.string.isRequired,
   link: PropTypes.string.isRequired,
   linktext: PropTypes.string.isRequired,
   idmlogin: PropTypes.string.isRequired,
};

export default ProductInfo;
