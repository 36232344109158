import React from 'react'
import HorizontalRule from '../util/HorizontalRule'
import ConfigManager from '../../config/configManager'
import PropTypes from 'prop-types'
import * as ProductFamilies from "../../constants/productFamilies";

const ActionBar = props => {
  const Config = ConfigManager()
  if (props.partnerValue&& props.partnerValue.toUpperCase()===ProductFamilies.PartnerHP) {
    return (
    
      <div className='actionBar TopBarMargin'>
        <HorizontalRule />
        <div className='actionBarContent'>
          <div style={{ flexGrow: 1 }}>
            <label>
              <input
                type='checkbox'
                checked={props.sendNews}
                onChange={props.handleSendNewsChange}
              />
              <span>
               Thank you for your interest in Omniverse Enterprise. As part of the trial, I understand that I will be contacted by HP to learn more about my experience.
              </span>
            </label>
            <div>
       
            <a
              href={Config.HPPrivacyPolicy}
              target='_blank'
              rel='noopener noreferrer'
            >
             View  HP’s privacy policy.
            </a>{' '}   
           
             
            </div>
          </div>
      
          <button
            type='submit'
            onClick={props.handleSubmitClick}
            disabled={!props.allowSubmit}
          >
            REGISTER
          </button>
        </div>
      </div>
    )
  }
  else   if (props.partnerValue&& props.partnerValue.toUpperCase()===ProductFamilies.PartnerWWT) {
    return (
    
      <div className='actionBar TopBarMargin'>
        <HorizontalRule />
        <div className='actionBarContent'>
          <div style={{ flexGrow: 1 }}>
            <label>
              <input
                type='checkbox'
                checked={props.sendNews}
                onChange={props.handleSendNewsChange}
              />
              <span>
               Thank you for your interest in Omniverse Enterprise. As part of the trial, I understand that I will be contacted by WWT to learn more about my experience.
              </span>
            </label>
            <div>
       
       <a
         href={Config.WWTPrivacyPolicy}
         target='_blank'
         rel='noopener noreferrer'
       >
       View  WWT’s privacy policy.
       </a>{' '}   
      
        
       </div>
          
          </div>
      
          <button
            type='submit'
            onClick={props.handleSubmitClick}
            disabled={!props.allowSubmit}
          >
            REGISTER
          </button>
        </div>
      </div>
    )
  }
  else   if (props.partnerValue&& props.partnerValue.toUpperCase()===ProductFamilies.PartnerESCAPE) {
    return (
    
      <div className='actionBar TopBarMargin'>
        <HorizontalRule />
        <div className='actionBarContent'>
          <div style={{ flexGrow: 1 }}>
            <label>
              <input
                type='checkbox'
                checked={props.sendNews}
                onChange={props.handleSendNewsChange}
              />
              <span>
               Thank you for your interest in Omniverse Enterprise. As part of the trial, I understand that I will be contacted by Escape to learn more about my experience.
              </span>
            </label>
            <div>
       
       <a
         href={Config.EscapePrivacyPolicy}
         target='_blank'
         rel='noopener noreferrer'
       >
      View  Escape’s privacy policy. 
       </a>{' '}   
      
        
       </div>
           
          </div>
      
          <button
            type='submit'
            onClick={props.handleSubmitClick}
            disabled={!props.allowSubmit}
          >
            REGISTER
          </button>
        </div>
      </div>
    )
  }
  else   if (props.partnerValue&& props.partnerValue.toUpperCase()===ProductFamilies.PartnerBoxx) {
    return (
    
      <div className='actionBar TopBarMargin'>
        <HorizontalRule />
        <div className='actionBarContent'>
          <div style={{ flexGrow: 1 }}>
            <label>
              <input
                type='checkbox'
                checked={props.sendNews}
                onChange={props.handleSendNewsChange}
              />
              <span>
               Thank you for your interest in Omniverse Enterprise. As part of the trial, I understand that I will be contacted by Boxx to learn more about my experience.
              </span>
            </label>
            <div>
       
       <a
         href={Config.BoxxPrivacyPolicy}
         target='_blank'
         rel='noopener noreferrer'
       >
      View  Boxx’s privacy policy. 
       </a>{' '}   
      
        
       </div>
           
          </div>
      
          <button
            type='submit'
            onClick={props.handleSubmitClick}
            disabled={!props.allowSubmit}
          >
            REGISTER
          </button>
        </div>
      </div>
    )
  }
  else   if (props.partnerValue&& props.partnerValue.toUpperCase()===ProductFamilies.PartnerLenovo) {
    return (
    
      <div className='actionBar TopBarMargin'>
        <HorizontalRule />
        <div className='actionBarContent'>
          <div style={{ flexGrow: 1 }}>
            <label>
              <input
                type='checkbox'
                checked={props.sendNews}
                onChange={props.handleSendNewsChange}
              />
              <span>
               Thank you for your interest in Omniverse Enterprise. As part of the trial, I understand that I will be contacted by Lenovo to learn more about my experience.
              </span>
            </label>
            <div>
       
       <a
         href={Config.LenovoPrivacyPolicy}
         target='_blank'
         rel='noopener noreferrer'
       >
      View  Lenovo’s privacy policy. 
       </a>{' '}   
      
        
       </div>
           
          </div>
      
          <button
            type='submit'
            onClick={props.handleSubmitClick}
            disabled={!props.allowSubmit}
          >
            REGISTER
          </button>
        </div>
      </div>
    )
  }
  else   if (props.partnerValue&& props.partnerValue.toUpperCase()===ProductFamilies.PartnerINSIGHT) {
    return (
    
      <div className='actionBar TopBarMargin'>
        <HorizontalRule />
        <div className='actionBarContent'>
          <div style={{ flexGrow: 1 }}>
            <label>
              <input
                type='checkbox'
                checked={props.sendNews}
                onChange={props.handleSendNewsChange}
              />
              <span>
               Thank you for your interest in Omniverse Enterprise. As part of the trial, I understand that I will be contacted by Insight to learn more about my experience.
              </span>
            </label>
            <div>
       
       <a
         href={Config.InsightPrivacyPolicy}
         target='_blank'
         rel='noopener noreferrer'
       >
      View  Insight’s privacy policy. 
       </a>{' '}   
      
        
       </div>
           
          </div>
      
          <button
            type='submit'
            onClick={props.handleSubmitClick}
            disabled={!props.allowSubmit}
          >
            REGISTER
          </button>
        </div>
      </div>
    )
  }
  if(props.IsRWA02)
  {
    return (
      
      <div className='actionBar TopBarMargin'>
      <HorizontalRule />
      <div className='actionBarContent'>
        <div style={{ flexGrow: 1 }}>
          <label>
            <input
              type='checkbox'
              checked={props.sendNews}
              onChange={props.handleSendNewsChange}
            />
            <span>
              Send me the latest enterprise news, announcements, and more from
              NVIDIA. I can unsubscribe at any time.
            </span>
          </label>
          <div>
            By registering for your NVIDIA Enterprise Account, you agree to{' '}
            <a
              href={Config.TermsLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              NVIDIA Account Terms and Conditions
            </a>{' '}
            ,{' '}
            <a
              href={Config.LegalInfo}
              target='_blank'
              rel='noopener noreferrer'
            >
              Legal Info
            </a>{' '}
            &amp;{' '}
            <a
              href={Config.PrivacyPolicy}
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
          </div>
        </div>
    
        <button
          type='submit'
          onClick={props.handleSubmitClick}
          disabled={!props.allowSubmit}
        >
          REGISTER
        </button>
      </div>
    </div>
    )
  }
  return (
    
    <div className='actionBar TopBarMargin'>
      <HorizontalRule />
      <div className='actionBarContent'>
        <div style={{ flexGrow: 1 }}>
          <label>
            <input
              type='checkbox'
              checked={props.sendNews}
              onChange={props.handleSendNewsChange}
            />
            <span>
              Send me the latest enterprise news, announcements, and more from
              NVIDIA. I can unsubscribe at any time.
            </span>
          </label>
          <div>
            By registering, you agree to{' '}
            <a
              href={Config.TermsLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              NVIDIA Account Terms and Conditions
            </a>{' '}
            ,{' '}
            <a
              href={Config.LegalInfo}
              target='_blank'
              rel='noopener noreferrer'
            >
              Legal Info
            </a>{' '}
            &amp;{' '}
            <a
              href={Config.PrivacyPolicy}
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
          </div>
        </div>
    
        <button
          type='submit'
          onClick={props.handleSubmitClick}
          disabled={!props.allowSubmit}
        >
          REGISTER
        </button>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  IsRWA02: PropTypes.string.isRequired,  
  partnerValue: PropTypes.string,
  sendNews: PropTypes.bool.isRequired,
  handleSendNewsChange: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  allowSubmit: PropTypes.bool.isRequired
}

export default ActionBar
