export const DGX = "DGX";
export const GRID = "GRID";
export const vgpu="VGPU";
export const NETQ="NETQ";
export const UFM="UFM";
export const omniverse="OMNIVERSE";
export const SpectrumX="SPECTRUM-X";
export const NvaiEnter="NVAIENTERPRISE";
export const Partneromniverse="PARTNEROMNIVERSE";
export const Partnerdell="DELL";
export const nvaie="NVAIE";
export const PartnerHP="HPI";
export const PartnerWWT="WWT";
export const PartnerESCAPE="ESCAPE";
export const PartnerINSIGHT="INSIGHT";
export const PartnerBoxx="BOXX";
export const PartnerLenovo="LENOVO";
export const NETQSAAS="NETQSAAS";





